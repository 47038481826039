import React, { useState, useEffect } from "react";
import '../App.css'
import CryptoJS from "crypto-js";
import axios from "axios";
import StepWizard from "react-step-wizard";
import base64 from "base-64";
import MuiAlert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import Backdrop from "@mui/material/Backdrop";
import NavBar from '../Components/navBar/navBar.jsx';
import Welcome from '../Components/Welcome/Welcome.jsx';
import AccountType from '../Components/AccounType/AccounType.jsx';
import PersonalInfo from '../Components/PersonalInfo/PersonalInfo';
import InfoAddress from "../Components/InfoAddress/infoAddress"
import Dashboard from '../Components/Dashboard/Dashboard';
import BusinessVerification from '../Components/BusinessVerification/BusinessVerification';
import ImagePerson from '../Components/ImagePerson/ImagePerson';
import "animate.css";
//import Recovery from '../Components/Recovery/Recovery';
//import Registro from '../Components/Register/Registro';
import PersonType from "./PersonType/PersonType.jsx";

const OnBoarding = () => {
  const [accountType, setAccountType] = useState("");
  const [step, setStep] = useState(1);
  const [config, setConfig] = useState({});
  const [client, setClient] = useState({});
  const [open, setOpen] = useState(false);
  const [ratePayment, setRatePayment] = useState([]);
  const [openAlertBlock, setOpenAlertBlock] = useState(false);
  const [openAlertConnect, setOpenAlertConnect] = useState(false);
  const [openAlertActivity, setOpenAlertActivity] = useState(false);


  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const hrefLocation = window.location.href;

    let id = urlParams.get("u");
    let httpRef = urlParams.get("o");
    let verification = urlParams.get("v");
    let idVerification = CryptoJS.HmacSHA256(
      id,
      "eSslulbmLXOGHljGwmBm"
    ).toString();

    httpRef = base64.decode(httpRef);
    let url = "";
    let token = "";
    let onBoarding = "";

    if (httpRef.includes("des")) {
      // url = process.env.REACT_APP_URL_DES;
      url = "https://legacy.payku.cl";
      // token = process.env.REACT_APP_TOKEN_DES;
      token = process.env.REACT_APP_TOKEN;
      onBoarding = process.env.REACT_APP_UPLOAD;
    } else if (httpRef.includes("legacy")) {
      // url = process.env.REACT_APP_URL;
      url = "https://legacy.payku.cl";
      // token = process.env.REACT_APP_TOKEN_DES;
      token = process.env.REACT_APP_TOKEN;
      onBoarding = process.env.REACT_APP_UPLOAD;
    } else if (httpRef.includes("app")) {
      // url = process.env.REACT_APP_URL;
      url = "https://legacy.payku.cl";
      token = process.env.REACT_APP_TOKEN;
      onBoarding = process.env.REACT_APP_UPLOAD;
    } else {
      // url = process.env.REACT_APP_URL_DEVQA;
      url = "https://legacy.payku.cl";
      // token = process.env.REACT_APP_TOKEN_DES;
      token = process.env.REACT_APP_TOKEN;
      onBoarding = process.env.REACT_APP_UPLOAD;
    }

    if (idVerification === verification) {
      if (id && httpRef) {
        const contain =
          !hrefLocation.includes("registro") &&
            !hrefLocation.includes("recuperarclave")
            ? true
            : false;
        if (contain) {
          setConfig({ user: id, httpRef: httpRef, url: url, token: token, onBoarding: onBoarding });

          axios
            .get(`${url}/api/ratepaymentv2`, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            })
            .then(function (response) {
              let arrayPayment = [];
              response.data.rate.forEach((paymentMethod) => {
                const defaultPayment = paymentMethod.parametros.reduce(
                  (paymentMethodA, paymentMethodB) =>
                    paymentMethodA.diahabil > paymentMethodB.diahabil
                      ? paymentMethodA
                      : paymentMethodB
                );
                arrayPayment.push([
                  paymentMethod.metodopago,
                  defaultPayment.id,
                ]);
              });
              setRatePayment(arrayPayment);
            })
            .catch(function (error) {
              //console.log(error);
            });
        }
      } else {
        //window.location.href = `${url}/index/iniciarsesion`;
      }
    } else {
      //window.location.href = `${url}/index/iniciarsesion`;
    }
  }, []);

  let custom = {
    enterRight: "animate__animated animate__fadeIn",
    enterLeft: "animate__animated animate__fadeIn",
    exitRight: "animate__animated animate__fadeOut",
    exitLeft: "animate__animated animate__fadeOut",
  };


  return (
    <>
      <div className="logo_content">
        <NavBar />
      </div>

      <div id="canvas-wrap">
        <div className="card-payku">
          <StepWizard className="wizard" transitions={custom}>

            <Welcome setStep={setStep} />

            <AccountType
              accountType={accountType}
              setAccountType={setAccountType}
              setStep={setStep}
              setClient={setClient}
              client={client}
              setOpen={setOpen}
              config={config}
              setOpenAlertActivity={setOpenAlertActivity}
            />
            <PersonType
              setStep={setStep}
              setClient={setClient}
              client={client}
            />
            <PersonalInfo
              setStep={setStep}
              setClient={setClient}
              client={client}
              config={config}
              ratePayment={ratePayment}
              setOpen={setOpen}
              setOpenAlertBlock={setOpenAlertBlock}
            />
            <InfoAddress
              accountType={accountType}
              setAccountType={setAccountType}
              setStep={setStep}
              setClient={setClient}
              client={client}
              config={config}
              ratePayment={ratePayment}
              setOpen={setOpen}
              setOpenAlertBlock={setOpenAlertBlock}
            />
            <ImagePerson
              setStep={setStep}
              client={client}
              setClient={setClient}
              config={config}
              ratePayment={ratePayment}
              setOpen={setOpen}
              setOpenAlertBlock={setOpenAlertBlock}
            />

            <BusinessVerification
              setStep={setStep}
              client={client}
              setClient={setClient}
              config={config}
              ratePayment={ratePayment}
              setOpen={setOpen}
              setOpenAlertBlock={setOpenAlertBlock}
            />


            <Dashboard setStep={setStep} client={client} config={config} />
          </StepWizard>
        </div>

      </div>

      <div className="stripe s-one-left"></div>
      <div className="stripe s-one-right"></div>

      {step > 1 ? (
        <>
          <div className="stripe s-two-left tanimate__animated animate__fadeIn"></div>
          <div className="stripe s-two-right animate__animated animate__fadeIn"></div>
        </>
      ) : (
        <>
          <div className="stripe s-two-left animate__animated animate__fadeOut"></div>
          <div className="stripe s-two-right animate__animated animate__fadeOut"></div>
        </>
      )}

      {step > 2 ? (
        <>
          <div className="stripe s-three-left animate__animated animate__fadeIn"></div>
          <div className="stripe s-three-right animate__animated animate__fadeIn"></div>
        </>
      ) : (
        <>
          <div className="stripe s-three-left animate__animated animate__fadeOut"></div>
          <div className="stripe s-three-right animate__animated animate__fadeOut"></div>
        </>
      )}

      {step > 3 ? (
        <>
          <div className="stripe s-four-left animate__animated animate__fadeIn"></div>
          <div className="stripe s-four-right animate__animated animate__fadeIn"></div>
        </>
      ) : (
        <>
          <div className="stripe s-four-left animate__animated animate__fadeOut"></div>
          <div className="stripe s-four-right animate__animated animate__fadeOut"></div>
        </>
      )}

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="primary" size="70px" />
      </Backdrop>

      <Snackbar open={openAlertBlock} autoHideDuration={6000}>
        <Alert severity="error" sx={{ width: "100%" }}>
          Hubo un problema al intentar crear su cuenta, por favor comuníquese
          con el área de soporte.
        </Alert>
      </Snackbar>
      <Snackbar
        open={openAlertConnect}
        autoHideDuration={3000}
        onClose={() => setOpenAlertConnect(false)}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          Hubo un problema de comunicación, por favor intente nuevamente.
        </Alert>
      </Snackbar>
      <Snackbar
        open={openAlertActivity}
        autoHideDuration={5000}
        onClose={() => setOpenAlertActivity(false)}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          Rut no posee inicio de actividades.
        </Alert>
      </Snackbar>



    </>
  )
}

export default OnBoarding